<template>
  <div>
    <el-container>
      <el-header>
        <div class="header">
          <div>
            <p class="name">茶叶质量评定知识库</p>
          </div>
          <div class="menu">
            <el-menu :default-active="$route.path"
                     mode="horizontal"
                     @select="handleSelect">
              <el-menu-item index="/knowBase/knowQA">知识问答</el-menu-item>
              <el-menu-item index="/knowBase/tea">茶叶标准样</el-menu-item>
              <el-menu-item index="/standardDesign">标准设计模式</el-menu-item>
              <el-menu-item index="/referenceExample">参考样例</el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import knowQA from './knowQA.vue'
export default {
  data() {
      return {
        pdfSrc: "http://nfsmid7.znglzx.cn/filedown/csff/standardDesign.pdf",
        pdfSrc2: "http://nfsmid7.znglzx.cn/filedown/csff/reference.pdf"
      }
    },
  components: {
    knowQA
  },
  methods: {
    handleSelect (key) {
      this.$router.push({
        path: key,
      });
    },
  }
}
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 25px;
  width: 100%;
  height: 100px;
  box-shadow: 0px 0px 10px rgba(14, 136, 41, 1);
}
.name {
  position: absolute;
  left: 15%;
  font-size: 24px;
  font-weight: bold;
}
.menu {
  width: 600px;
  position: absolute;
  right: 10%;
  margin-top: 13px;
}
.el-menu {
  border-bottom: none !important;
}
.el-menu-item {
  font-size: 16px;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #f6f8f6 !important;
  border-radius: 5px;
  background: #147014 !important;
  border-bottom: none !important;
}
.el-menu-item.is-active {
  color: rgb(16, 194, 31) !important;
  background: none !important;
  border-bottom: none !important;
  outline: 0 !important;
}
a{
  text-decoration: none;
}
a:visited {
	    color: black; /*已访问的链接颜色*/
    }
a:active{
  color: rgb(16, 194, 31)
}
</style>